<template>
  <div>
    <el-form>
      <el-form-item label="代理时间">
        <el-date-picker
          v-model="timeList"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd hh:mm:ss"
        >
        </el-date-picker>
        <el-button type="success" @click="updateTime">保存</el-button>
      </el-form-item>
      <el-form-item label="开发分成">
        <el-input v-model="dev_ratio" placeholder="联系人名称"></el-input>
        <el-button
          type="success"
          v-if="ratioInfoDev.id"
          @click="updateMPInfo('dev')"
          >保存设置</el-button
        >
        <el-button type="success" v-else @click="saveMPInfo('dev')"
          >添加设置</el-button
        >
      </el-form-item>
      <el-form-item label="管理分成">
        <el-input v-model="mp_ratio" placeholder="联系人名称"></el-input>
        <el-button
          type="success"
          v-if="ratioInfoMp.id"
          @click="updateMPInfo('mp')"
          >保存设置</el-button
        >
        <el-button type="success" v-else @click="saveMPInfo('mp')"
          >添加设置</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    cooperator: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      timeList: [],
      dev_ratio: 0,
      mp_ratio: 0,
      coop_id: 0,
      ratioInfoDev: {},
      ratioInfoMp: {},
    };
  },
  watch:{
    cooperator:{
        handler:function(nv){
            if(nv.id && nv.end_time){
                this.timeList = []
                this.timeList.push(nv.start_time)
                this.timeList.push(nv.end_time)
            }
        },
        immediate:true
    }
  },
  mounted() {
    this.coop_id = this.$route.query.id;
    this.queryRatio();
  },

  methods: {
    load(){

    },
    updateTime() {
      if (this.timeList.length == 0) {
        this.$message("请选择时间");
        return;
      }
      this.$confirm("确定要修改主理时间？").then(() => {
        this.$post("user/cooperator/update", {
          id: this.coop_id,
          start_time: this.timeList[0],
          end_time: this.timeList[1],
        });
      });
    },
    queryRatio() {
      this.$get("user/agentAreaDivide/query", {
        coop_id: this.coop_id,
      }).then((res) => {
        res.data.data.forEach((item) => {
          if (item.type == "mp_dev") {
            this.ratioInfoDev = item;
            this.dev_ratio = item.divide_ratio;
          }
          if (item.type == "mp_managing") {
            this.ratioInfoMp = item;
            this.mp_ratio = item.divide_ratio;
          }
        });
      });
    },
    updateMPInfo(type) {
      let data = {};
      if (type == "dev") {
        if (this.dev_ratio > 0.5) {
          this.$message("开发分成最多0.5");
          return;
        }
        data.id = this.ratioInfoDev.id;
        data.divide_ratio = this.dev_ratio;
      } else {
        if (this.mp_ratio > 0.3) {
          this.$message("管理分成最多0.3");
          return;
        }
        data.id = this.ratioInfoMp.id;
        data.divide_ratio = this.mp_ratio;
      }
      this.$post("user/agentAreaDivide/update", data).then((res) => {
        this.$message("成功");
      });
    },
    saveMPInfo(type) {
      let data = {
        coop_id: this.coop_id,
      };
      if (type == "dev") {
        if (this.dev_ratio > 0.5) {
          this.$message("开发分成最多0.5");
          return;
        }
        data.name = "主理人开发分成";
        data.type = "mp_dev";
        data.divide_ratio = this.dev_ratio;
      } else {
        if (this.mp_ratio > 0.3) {
          this.$message("管理分成最多0.3");
          return;
        }
        data.name = "主理人管理分成";
        data.type = "mp_managing";
        data.divide_ratio = this.mp_ratio;
      }
      console.log(data);
      this.$post("user/agentAreaDivide/insert", data).then((res) => {
        this.$message("成功");
      });
    },
  },
};
</script>